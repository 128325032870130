Vue.http.headers.common['X-CSRF-TOKEN'] = $('meta[name="csrf_token"]').attr('content');

Vue.filter('reverseDate', function(value) {
	return value.split('-').reverse().join('-');
});

Vue.filter('subString', function(value, start, end) {
	return value.substring(parseInt(start), parseInt(end));
});

Vue.filter('nullToEmptyString', function(value) {
	if (value === undefined || value === null) {
	    return '';
	}
	return value;
})

Vue.filter('formatBytes', function(bytes) {
	var decimals = 2;
	if(bytes == 0) return '0 Byte';
   	var k = 1000;
   	var dm = decimals + 1 || 3;
   	var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
   	var i = Math.floor(Math.log(bytes) / Math.log(k));
   	return (bytes / Math.pow(k, i)).toPrecision(dm) + ' ' + sizes[i];
});

Vue.filter('nl2hr', function(string) {
	return string.trim().replace(/(?:\r\n|\r|\n)/g, '<hr>');
});

Vue.filter('nl2br', function(string) {
	return string.trim().replace(/(?:\r\n|\r|\n)/g, '<br />');
});