$(document).ready(function() {

	updownarrows();

	// Void/Delete Authorised/Draft Invoices
	$("#voidOrDeleteInvoices").on('click', function() {

		var drafts = [];
		var authorised = [];
		var credits = [];

		$('.case').each(function() {
			if(this.checked) {

				if($(this).data('credited') != '0.00') {
					credits.push(1);
				}

				if($(this).data('invoice').substring(0, 3).toLowerCase() == 'odr') {
					drafts.push($(this).data('invoice'));
				} else {
					authorised.push($(this).data('invoice'));
				}
			}    
		});

		if(credits.length > 0) {
			bootbox.alert('Invoices with credit notes applied cannot be voided.');
			return;
		}

		bootbox.confirm('Are you sure you want to VOID or DELETE the selected invoices?', function(result) {
			if(result) {
				// void all selected AUTHORISED invoices
				if(authorised.length == 0 && drafts.length == 0) {
					bootbox.alert('Please select at least one invoice.');
				} else {
					startHoldTight();
					$.post('invoices/void-or-delete', { _token: $('meta[name=csrf_token]').attr("content"),  authorised: authorised, drafts: drafts}, function(data){
						if(data === 'success') {
							window.location.reload();
						} else {
							bootbox.alert(data);
						}
						stopHoldTight();
					});
				}
			}
		});

	});


	/******************************************/
	/****  DRAFT INVOICES
	/******************************************/

	// Delete the selected invoices
	$('#deleteDrafts').on('click', function() {
		var invoices = [];
		
		$('.case').each(function() {
			if(this.checked)
				invoices.push($(this).data('invoice'));
		});

		if(invoices.length == 0) {
			bootbox.alert('No invoices selected');
			return;
		}

		console.log(invoices);

		bootbox.confirm('Are you sure you want to DELETE the selected invoices?', function(result) {
			if(result) {
				startHoldTight();
				$.post('invoices/delete', { _token: $('meta[name=csrf_token]').attr("content"),  invoices: invoices}, function(data){
					if(data === 'success') {
						window.location.reload();
					} else {
						stopHoldTight();
						bootbox.alert(data);
					}
				});
			}
		});
	});

	// Voids the selected invoices
	$("#void").on('click', function() {

		var invoices = [];
		var credits = [];

		$('.case').each(function() {
			if(this.checked) {

				if($(this).data('credited') != '0.00') {
					credits.push(1);
				}
				invoices.push($(this).data('invoice'));
			}    
		});

		if(credits.length > 0) {
			bootbox.alert('Invoices with credit notes applied cannot be voided.');
			return;
		}

		if(invoices.length == 0) {
			bootbox.alert('No invoices selected');
			return;
		}

		bootbox.confirm('Are you sure you want to VOID the selected invoices?', function(result) {
			if(result) {
				startHoldTight();
				$.post('invoices/void', { _token: $('meta[name=csrf_token]').attr("content"),  invoices: invoices}, function(data){
					if(data === 'success') {
						window.location.reload();
					} else {
						stopHoldTight();
						bootbox.alert(data);
					}
				});
			}
		});

	});

	// Marks the selected invoices as AUTHORISED and prints them
	$("#authoriseAndPrintMulipleDrafts").on('click', function() {
		var drafts = [];

		$('.case').each(function() {
			if(this.checked) {
				if($(this).data('invoice').substring(0, 3).toLowerCase() == 'odr') {
					drafts.push($(this).data('invoice'));
				}
			}    
		});

		if(drafts.length == 0) {
			bootbox.alert('No draft invoices selected.');
			return;
		}

		bootbox.confirm('Are you sure you want to authorise and print the selected draft invoices?', function(result) {

			// register callback 
			var authoriseAndSendToPrinter = function(invoices, print, printer) {
				startHoldTight();
				$.post('invoices/authorise-invoices', { 
					_token: $('meta[name=csrf_token]').attr("content"),
					invoices: invoices,
					print: print,
					printer: printer }, 
					function(data) {
						if(data === 'success') {
							window.location.reload();
						} else {
							stopHoldTight();
							bootbox.alert(data);
						}
				});
			}

			if(result) {
				bootbox.dialog({
				  message: "Select the printer you wish to print to.",
				  title: "printer selection",
				  buttons: {
				    danger: {
				      label: "Cancel",
				      className: "btn-danger",
				      callback: function() {
				        return;
				      }
				    },
				    success: {
				      label: "Print to Dock",
				      className: "btn-primary",
				      callback: function() {
				        authoriseAndSendToPrinter(drafts, true, 'Dock');
				      }
				    },
				    main: {
				      label: "Print to Office",
				      className: "btn-primary",
				      callback: function() {
				      	authoriseAndSendToPrinter(drafts, true, 'Office');
				      }
				    }
				  }
				});
			}
		});
	});

	// Marks the selected invoices as AUTHORISED
	$("#authoriseMulipleDrafts").on('click', function() {
		var drafts = [];

		$('.case').each(function() {
			if(this.checked) {
				if($(this).data('invoice').substring(0, 3).toLowerCase() == 'odr') {
					drafts.push($(this).data('invoice'));
				}
			}    
		});

		if(drafts.length == 0) {
			bootbox.alert('No draft invoices selected.');
			return;
		}

		bootbox.confirm('Are you sure you want to authorise the selected draft invoices?', function(result) {
			if(result) {
				startHoldTight();
				$.post('invoices/authorise-invoices', { _token: $('meta[name=csrf_token]').attr("content"),  invoices: drafts }, function(data){
					if(data === 'success') {
						window.location.reload();
					} else {
						stopHoldTight();
						bootbox.alert(data);
					}
				});
			}
		});
	});

	$("#authoriseAndPrint").on('click', function() {
		var invoice = $(this).data('invoice');
		
		startHoldTight();

		$.post('invoices/authorise', { _token: $('meta[name=csrf_token]').attr("content"),  invoices: invoices}, function(data){
			if(data === 'success') {			
				window.location.reload();
				$
			} else {
				stopHoldTight();
				bootbox.alert(data);
			}
		});
	});

	$('input[name=prefill]').on('change', function(){
		var data = $(this).data('load');
		window.location = window.location.pathname+'?prefill='+data;
		// alert(window.location.pathname+'?prefill='+data);
	});

	$('input[name=group]').on('change', function(){
		var data = $(this).data('load');
		window.location = window.location.pathname+'?group='+data;
		// alert(window.location.pathname+'?prefill='+data);
	});

	$('#COD-btn').on('click', function(evt) {
		evt.preventDefault();
		$('input[name=due_date]').val('COD');
	});

	//adds extra table rows
	$(".addmore").on('click',function(){
		if($('#table-count').val() == '')
			var i = $('table tr').length;
		else
			var i = $('#table-count').val();

		
		html = '<tr>';
		html += '<td><input class="case" type="checkbox"></td>';
		html += '<td><input type="text" data-type="itemCode" name="itemCode[]" id="itemCode_'+i+'" class="form-control autocomplete_txt" autocomplete="off"></td>';
		html += '<td><input type="text" data-type="itemDescription" name="itemDescription[]" id="itemDescription_'+i+'" class="form-control autocomplete_txt" autocomplete="off"></td>';
		html += '<td><input type="text" name="price[]" id="price_'+i+'" class="form-control changesNo" autocomplete="off" onkeypress="return IsNumeric(event);" ondrop="return false;" onpaste="return false;"></td>';
		html += '<td><input type="text" name="quantity[]" id="quantity_'+i+'" class="form-control changesNo" autocomplete="off" onkeypress="return IsNumeric(event);" ondrop="return false;" onpaste="return false;"></td>';
		html += '<td><input type="text" readonly name="total[]" id="total_'+i+'" class="form-control totalLinePrice" autocomplete="off" onkeypress="return IsNumeric(event);" ondrop="return false;" onpaste="return false;"></td>';

		// don't add the checkbox for group items
		// if($('input[name=is_group]').val() == 0) {
		// 	html += '<td><input class="_save save_'+i+'" pull-right" data-linenum="'+i+'" data-product_code="" data-contact_id="" type="checkbox"></td>';
		// }

		html += '</tr>';
		$('table').append(html);
		i++;
		$('#table-count').val(i);
		updownarrows();

	});

	//deletes the selected table rows
	$(".delete").on('click', function() {
		$('.case:checkbox:not(:checked)').parents("tr").remove();
		$('#check_all').attr("checked", false);
		calculateTotal();
		$('.addmore').trigger('click');
	});


	//check all checkboxes
	$(document).on('change','#check_all',function() {
		$('input[class=case]:checkbox').prop("checked", $(this).is(':checked'));
		$('.case').trigger('change');
		calculateTotal();
	});

	//check individual
	$(document).on('change','.case',function() {
		if($(this).is(':checked')) {
			// $(this).parents('tr').css("background", "#18A689");
			$(this).parents('tr').css("background", "#000");
			$(this).parents('tr').css("color", "#fff");
		} else {
			$(this).parents('tr').css("background", "#fff");
			$(this).parents('tr').css("color", "#5b5b5b");
		}
		calculateTotal();
	});


	//check individual
	$(document).on('change', '._save', function() {

		var item_id = $('body').find($(this)).data('id');
		var line_num = $('body').find($(this)).data('linenum');
		var line = $('body').find('.save_'+line_num);
		var contact_id = $('body').find('.save_'+line_num).data('contact_id');
		var product_code = $('body').find('.save_'+line_num).data('product_code');
		var description = $('body').find('#itemDescription_'+line_num).val();
		var price = $('body').find('#price_'+line_num).val();

		if(line.is(':checked')) {

			if(description == '' || price == '') {
				line.attr('checkbox', false);
				alert('You must add a product first.')
				return false;

			}

			$.ajax({
		        type: 'PUT',
		        url: '/products/update-product/'+item_id,
		        data: { _token: $('meta[name=csrf_token]').attr("content"), description: description, price: price, contact_id: contact_id, product_code: product_code },
		        success: function(data) {
		        	if(data === 'updated') {
						$.notify({message: "Product updated."},{type: "success"});
					} else if(data === 'inserted') {
						$.notify({message: "Product added to this contacts customised items."},{type: "success"});
						$('body').find('#price_'+line_num).closest('td').css('background-color', '');
					} else {
						bootbox.alert(data);
					}
		        }
		    });
		} else {
			return false;
		}
	});


	//autocomplete script
	$(document).on('focus','.autocomplete_txt',function(){
		
		type = $(this).data('type');
		if(type =='itemCode' )
			autoTypeNo = 0;
		if(type =='itemDescription' )
			autoTypeNo = 1; 	
		
		$(this).autocomplete({
			source: function( request, response ) {	 
				 var array = $.map(products, function (item) {
	                 var code = item.split("|");
	                 return {
	                     label: code[autoTypeNo],
	                     value: code[autoTypeNo],
	                     data : item
	                 }
	             });
	             //call the filter here
	             response($.ui.autocomplete.filter(array, request.term).slice(0, 10));
			},
			autoFocus: true,	      	
			minLength: 1,
			select: function( event, ui ) {
				// alert($('#table-count').val());
				var names = ui.item.data.split("|");
				id_arr = $(this).attr('id');
		  		id = id_arr.split("_");
				$('#itemCode_'+id[1]).val(names[0]);
				$('#itemDescription_'+id[1]).val(names[1]);
				//$('#quantity_'+id[1]).val(parseFloat(names[3]).toFixed(3));
				$('#price_'+id[1]).val(parseFloat(names[2]).toFixed(2));
				//$('#total_'+id[1]).val(parseFloat((names[3]*names[2])).toFixed(2));

				if(names[4] == 0) {
					$('#price_'+id[1]).closest('td').css('background', '#F7E733');
				}

				$('#itemCode_'+id[1]).closest('tr').find('[type=checkbox]').prop('checked', true);
		  		$('#itemCode_'+id[1]).closest('tr').css("background", "#18A689");

				$('.save_'+id[1]).data('product_code', $('#itemCode_'+id[1]).val());
				$('.save_'+id[1]).data('contact_id', $('input[name=contactId]').val());
				$('.save_'+id[1]).attr('checked', false);

				$('.ui-widget-content').hide();
				$('.addmore').trigger('click');
				calculateTotal();

			}		      	
		});
	});

	//price change
	$(document).on('change keyup blur','.changesNo',function(){
		id_arr = $(this).attr('id');
		id = id_arr.split("_");
		quantity = $('#quantity_'+id[1]).val();
		price = $('#price_'+id[1]).val();
		if( quantity!='' && price !='' ) 
			$('#total_'+id[1]).val( (parseFloat(price)*parseFloat(quantity)).toFixed(2) );	
		calculateTotal();
	});

	$(document).on('change keyup blur','#tax',function(){
		calculateTotal();
	});

	$(document).on('change keyup blur','#amountPaid',function(){
		calculateAmountDue();
	});

	// Submit draft invoice NO PRINT
	$('#submitNewDraftInvoice').on("click", function() {

		$('input[name=print_invoice]').val('0');
		$('input[name=invoice_type]').val('DRAFT');
		$('.delete').trigger('click');

		bootbox.confirm('Are you sure you want to create this invoice?', function(result) {
			if(!result)
				return;

			// Remove uncheck boxes before submitting invoice
			createInvoice('');
		});
	    return false; // avoid to execute the actual submit of the form.
	});

	// Submit DRAFT invoice AND PRINT
	$('#submitAndPrintDraftInvoice').on("click", function() {
		$('input[name=print_invoice]').val('1');
		$('input[name=invoice_type]').val('DRAFT');
		$('.delete').trigger('click');

		bootbox.confirm('Are you sure you want to create this invoice?', function(result) {
			if(!result)
				return;

			bootbox.dialog({
			  message: "Select the printer you wish to print to.",
			  title: "printer selection",
			  buttons: {
			    danger: {
			      label: "Cancel",
			      className: "btn-danger",
			      callback: function() {
			        return;
			      }
			    },
			    success: {
			      label: "Print to Dock",
			      className: "btn-primary",
			      callback: function() {
			        createInvoice('dock');
			      }
			    },
			    main: {
			      label: "Print to Office",
			      className: "btn-primary",
			      callback: function() {
			      	createInvoice('office');
			      }
			    }
			  }
			});
		});

		
	    return false; // avoid to execute the actual submit of the form.
	});

	// Submit tax invoice NO PRINT
	$('#submitNewATaxInvoice').on("click", function() {
		$('input[name=print_invoice]').val('0');
		$('input[name=invoice_type]').val('AUTHORISED');
		$('.delete').trigger('click');
		
		bootbox.confirm('Are you sure you want to create this invoice?', function(result) {
			if(!result)
				return;

			// Remove uncheck boxes before submitting invoice
			createInvoice('');
		});
	    return false; // avoid to execute the actual submit of the form.
	});

	// Submit tax invoice AND PRINT
	$('#submitAndPrintNewInvoice').on("click", function() {
		$('input[name=print_invoice]').val('1');
		$('input[name=invoice_type]').val('AUTHORISED');
		$('.delete').trigger('click');
		
		bootbox.confirm('Are you sure you want to create this invoice?', function(result) {
			if(!result)
				return;

			bootbox.dialog({
			  message: "Select the printer you wish to print to.",
			  title: "printer selection",
			  buttons: {
			    danger: {
			      label: "Cancel",
			      className: "btn-danger",
			      callback: function() {
			        return;
			      }
			    },
			    success: {
			      label: "Print to Dock",
			      className: "btn-primary",
			      callback: function() {
			        createInvoice('dock');
			      }
			    },
			    main: {
			      label: "Print to Office",
			      className: "btn-primary",
			      callback: function() {
			      	createInvoice('office');
			      }
			    }
			  }
			});

		});
	    return false; // avoid to execute the actual submit of the form.
	});

	// Submit draft invoice NO PRINT
	$('#saveAsDraftInvoice').on("click", function() {
		$('input[name=print_invoice]').val('0');
		$('input[name=invoice_type]').val('DRAFT');
		$('.delete').trigger('click');

		editExistingInvoice('');
	    return false; // avoid to execute the actual submit of the form.
	});

	// Submit draft invoice AND PRINT
	$('#saveAsDraftInvoiceAndPrint').on("click", function() {
		$('input[name=print_invoice]').val('1');
		$('input[name=invoice_type]').val('DRAFT');
		$('.delete').trigger('click');

		bootbox.dialog({
		  message: "Select the printer you wish to print to.",
		  title: "printer selection",
		  buttons: {
		    danger: {
		      label: "Cancel",
		      className: "btn-danger",
		      callback: function() {
		        return;
		      }
		    },
		    success: {
		      label: "Print to Dock",
		      className: "btn-primary",
		      callback: function() {
		        editExistingInvoice('dock');
		      }
		    },
		    main: {
		      label: "Print to Office",
		      className: "btn-primary",
		      callback: function() {
		      	editExistingInvoice('office');
		      }
		    }
		  }
		});
	    return false; // avoid to execute the actual submit of the form.
	});

	// Submit tax invoice NO PRINT
	$('#saveAsTaxInvoice').on("click", function() {
		$('input[name=print_invoice]').val('0');
		$('input[name=invoice_type]').val('AUTHORISED');
		$('.delete').trigger('click');
		editExistingInvoice('');
	    return false; // avoid to execute the actual submit of the form.
	});

	// Submit tax invoice AND PRINT
	$('#saveAsTaxInvoiceAndPrint').on("click", function() {
		$('input[name=print_invoice]').val('1');
		$('input[name=invoice_type]').val('AUTHORISED');
		$('.delete').trigger('click');

		bootbox.dialog({
		  message: "Select the printer you wish to print to.",
		  title: "printer selection",
		  buttons: {
		    danger: {
		      label: "Cancel",
		      className: "btn-danger",
		      callback: function() {
		        return;
		      }
		    },
		    success: {
		      label: "Print to Dock",
		      className: "btn-primary",
		      callback: function() {
		        editExistingInvoice('dock');
		      }
		    },
		    main: {
		      label: "Print to Office",
		      className: "btn-primary",
		      callback: function() {
		      	editExistingInvoice('office');
		      }
		    }
		  }
		});
	    return false; // avoid to execute the actual submit of the form.
	});

});
// end ready

//It restrict the non-numbers
var specialKeys = new Array();
specialKeys.push(8,46); //Backspace
function IsNumeric(e) {
    var keyCode = e.which ? e.which : e.keyCode;
    // console.log( keyCode );
    var ret = ((keyCode >= 48 && keyCode <= 57) || specialKeys.indexOf(keyCode) != -1);
    return ret;
}


function updownarrows() {
	$('input[id^="quantity_"]')
		.bind('keyup', function(e) {
		    var $this = $(this);
		    var $tr = $this.closest("tr");
		    var id = this.id.substring(0, this.id.indexOf("_"));
		    
		    if(e.keyCode == 38){
		        $tr.prev().find('input[id^='+id+']').focus();
		    }
		    if(e.keyCode == 40)
		    {
		       $tr.next().find('input[id^='+id+']').focus();
		    }
		}	
	); 

}

function fetchContactInvoices(contact_id) {

    $(".modal-body.invoices").html(fetching('Invoices'));

    $.get('/accounts/get-invoices/' + contact_id, function(data) {
        $('.modal-body.invoices').html(data);
        $('table.invoices').DataTable({
          "order": [[ 0, "desc" ]],
          "lengthMenu": [[10, 15, -1], [10, 15, "All"]]
        });
    });
}

function sendInvoiceToPrintQueue(invoice_id) {

	var submitInvoiceForPrinting = function(invoice_id, printer) {
		startHoldTight();

		$.get('/invoices/' + invoice_id + '/print?printer='+printer, function(data) {
			if(data === 'success') {
				$.notify({message: "Invoice added to the "+printer+" print queue."},{type: "success"});
			} else {
				bootbox.alert("An unknown error occured.");
			}
			stopHoldTight();
		});
	}

	bootbox.dialog({
	  message: "Select the printer you wish to print to.",
	  title: "printer selection",
	  buttons: {
	    danger: {
	      label: "Cancel",
	      className: "btn-danger",
	      callback: function() {
	        // nothing to do
	      }
	    },
	    success: {
	      label: "Print to Dock",
	      className: "btn-primary",
	      callback: function() {
	        submitInvoiceForPrinting(invoice_id, 'Dock');
	      }
	    },
	    main: {
	      label: "Print to Office",
	      className: "btn-primary",
	      callback: function() {
	      	submitInvoiceForPrinting(invoice_id, 'Office');
	      }
	    }
	  }
	});
}

function fetchInvoicePdf(invoice_id) {
	startHoldTight();
    $('body').find('.btn-'+invoice_id+' i').removeClass('fa-file-pdf-o');
    $('body').find('.btn-'+invoice_id+' i').addClass('fa-spinner faa-spin animated');    
    window.location = "invoices/"+invoice_id;
    setTimeout(function() {
        $('body').find('.btn-'+invoice_id+' i').removeClass('fa-spinner faa-spin animated');
        $('body').find('.btn-'+invoice_id+' i').addClass('fa-file-pdf-o');
        stopHoldTight();
    }, 3000);
}

function printPackingSlip(invoice_id) {

	var submitPackingSlipForPrinting = function(invoice_id, printer) {
		startHoldTight();

		$.get('/invoices/' + invoice_id + '/print?printer='+printer+'&packing_slip=true', function(data) {
			if(data === 'success') {
				$.notify({message: "Packing slip added to the "+printer+" print queue."},{type: "success"});
			} else {
				bootbox.alert("An unknown error occured.");
			}
			stopHoldTight();
		});
	}

	bootbox.dialog({
	  message: "Select the printer you wish to print to.",
	  title: "printer selection",
	  buttons: {
	    danger: {
	      label: "Cancel",
	      className: "btn-danger",
	      callback: function() {
	        // nothing to do
	      }
	    },
	    success: {
	      label: "Print to Dock",
	      className: "btn-primary",
	      callback: function() {
	        submitPackingSlipForPrinting(invoice_id, 'Dock');
	      }
	    },
	    main: {
	      label: "Print to Office",
	      className: "btn-primary",
	      callback: function() {
	      	submitPackingSlipForPrinting(invoice_id, 'Office');
	      }
	    },
	    download: {
	      label: "Download",
	      className: "btn-warning",
	      callback: function() {
	      	 window.open('/cron/print-queued-invoice?invoice='+invoice_id+'&download=true&packing_slip=1','_blank');
	      }
	    }
	  }
	});
}

function authoriseAndPrintedInvoice(invoice_no, invoice_id) {
    // register callback
	var authoriseAndSendInvoiceForPrinting = function (invoice_no, printer) {
		startHoldTight();
	    
		$.post('invoices/authorise', { _token:  $('meta[name=csrf_token]').attr("content"),  invoices: [invoice_no], printer: printer }, function(data){
			stopHoldTight()
			if(data === 'success') {
				$('body').find('.tr-'+invoice_no).remove();
				$.notify({message: "Invoice authorised and added to print queue."},{type: "success"});
			} else {
				bootbox.alert(data);
			}
		});
	}


	bootbox.dialog({
	  message: "Select the printer you wish to print to.",
	  title: "printer selection",
	  buttons: {
	    danger: {
	      label: "Cancel",
	      className: "btn-danger",
	      callback: function() {
	        // nothing to do
	      }
	    },
	    success: {
	      label: "Print to Dock",
	      className: "btn-primary",
	      callback: function() {
	        authoriseAndSendInvoiceForPrinting(invoice_no, 'Dock');
	      }
	    },
	    main: {
	      label: "Print to Office",
	      className: "btn-primary",
	      callback: function() {
	      	authoriseAndSendInvoiceForPrinting(invoice_no, 'Office');
	      }
	    }
	  }
	});
}

//total price calculation 
function calculateTotal() {
	subTotal = 0 ;
	total = 0; 
	$('.totalLinePrice').each(function(){
		if($(this).val() != '' )subTotal += parseFloat( $(this).val() );
	});
	$('#subTotal').val( subTotal.toFixed(2) );
	tax = $('#tax').val();
	if(tax != '' && typeof(tax) != "undefined" ){
		taxAmount = subTotal * ( parseFloat(tax) /100 );
		$('#taxAmount').val(taxAmount.toFixed(2));
		total = subTotal + taxAmount;
	}else{
		$('#taxAmount').val(0);
		total = subTotal;
	}
	$('#totalAftertax').val( total.toFixed(2) );
	calculateAmountDue();
}


//due amount calculation
function calculateAmountDue(){
	amountPaid = $('#amountPaid').val();
	total = $('#totalAftertax').val();
	if(amountPaid != '' && typeof(amountPaid) != "undefined" ){
		amountDue = parseFloat(total) - parseFloat( amountPaid );
		$('.amountDue').val( amountDue.toFixed(2) );
	}else{
		total = parseFloat(total).toFixed(2);
		$('.amountDue').val( total);
	}
}


function createInvoice(printer) {

	// set the printer to print to
	$('input[name=print_printer]').val(printer);

	startHoldTight();
	$.ajax({
        type: "POST",
        url: $('#new-invoice').attr('action'),
        data: $("#new-invoice").serialize(), // serializes the form's elements.
        success: function(data)
        {	
        	if(data == 'success') {

        		// $('#check_all').trigger('click');
		        // $('.delete').trigger('click');
		        // $('.addmore').trigger('click');
		        // $('textarea[name=notes]').val('');
		        // $('input[name=reference]').val('');
		        // if($('select[name=delivery_address]').length)
		        // 	$('select[name=delivery_address]').val('-');

		        window.top.location = '/accounts';

        	} else {
        		stopHoldTight();
        		bootbox.alert(data);
        	}
        }
    });
}

function editExistingInvoice(printer) {

	// set the printer to print to
	$('input[name=print_printer]').val(printer);
	
	startHoldTight();
	var customerID = $('input[name=contactId]').val();

	$.ajax({
        type: "PUT",
        url: $('#edit-invoice').attr('action'),
        data: $("#edit-invoice").serialize(), // serializes the form's elements.
        success: function(data)
        {
        	var dataSplit = data.split("|");
		
			console.log(dataSplit);
			console.log(data);
		
        	if(data == 'success' || dataSplit[0] == 'success') {

				// $('#check_all').trigger('click');
		        // $('.delete').trigger('click');
		        // $('.addmore').trigger('click');
		        // $('textarea[name=notes]').val('');
		        // $('input[name=reference]').val('');
		        //  if($('select[name=delivery_address]').length)
		        // 	$('select[name=delivery_address]').val('-');

		        window.top.location = dataSplit[1];
		        
        	}
        	else {
	        	stopHoldTight();
        		bootbox.alert(data);
        	}
        }
    });
}


	