$(document).ready(function() {

	$('#edit-user-form').on('submit', function(event) {
		
		$submitFlag = true;
		startHoldTight();

		if($('#edit-user-form input[name=password]').val().length > 0 || $('#edit-user-form input[name=confirm_password]').val().length > 0)
		{
			if($('#edit-user-form input[name=password]').val() !== $('#edit-user-form input[name=confirm_password]').val())
			{
				bootBoxAlert("If you wish to change the user's password, both password fields must match.");
				$submitFlag = false;
			}
		} 
		
		if($submitFlag === true)
		{
			return true; 
		}
		else
		{
			stopHoldTight();
			return false;
		}
	});


	$('.edit-user-btn').on("click", function(evt) {
		evt.preventDefault();

		$user_id = $(this).data('user-id');
		$url = 'users/'+$user_id+'/edit';
		$method = 'get';
		
		$.ajax({
			url: $url,
			type: $method,
			success:function(response){
			  if(response !== null && typeof response === 'object')
			  {
				  $('#edit-user-form input[name=user_id]').val(response['id']);
				  $('#edit-user-form input[name=first_name]').val(response['first_name']);
				  $('#edit-user-form input[name=last_name]').val(response['last_name']);
				  $('#edit-user-form input[name=email]').val(response['email']);
				  $('#edit-user-form select[name=role]').val(response['role']);
				  $('#edit-user-form input[name=password]').val('');
				  $('#edit-user-form input[name=confirm_password]').val('');

				  $('#edit-user-form').attr("action", "users/"+response['id'])
			  }
			  else
			  { 
				bootBoxAlert(response);
			  }
			}
		});
	});

	$('.delete-address').click(function() {
	  $('.bootbox-confirm').trigger('click');
	});

	$('.bootbox-confirm').click(function() { 
	  $btn = $(this);
	  $required = ['url', 'title', 'method', 'message'];

	  //check that all required attributes are present
	  for($i = 0; $i < $required.length; $i++) {
		if(undefined === $(this).data($required[$i]) || $(this).data($required[$i]).length < 1) {
		  bootBoxAlert('data-' + $required[$i] + ' attribute not set.');
		  return;
		}
	  }

	  /* REQUIRED DATA */
	  $title = $(this).data('title'); 
	  $message = $(this).data('message');
	  $url = $(this).data('url');
	  $ajax = (undefined === $(this).data('ajax'))? false: true;

	  /* OPTIONAL DATA */ 
	  $data = $(this).data('data');
	  $method = ($(this).data('method'))? $(this).data('method') : 'post';
	  $successLabel = ($(this).data('success-label'))? $(this).data('success-label') : 'confirm';
	  $successClass = ($(this).data('success-class'))? $(this).data('success-class') : 'btn-success';
	  $dangerLabel = ($(this).data('danger-label'))? $(this).data('danger-label') :  'cancel';
	  $dangerClass = ($(this).data('danger-class'))? $(this).data('danger-class') : 'btn-danger';

	  bootbox.dialog({
		message: $message,
		title: $title.toUpperCase(),
		buttons: {
		  success: {
			label: $successLabel.toUpperCase(),
			className: $successClass,
			callback: function() {
			  if($ajax) {
				bootBoxAjax();
			  }
			  else {
				location.reload();
			  }         
			}
		  },
		  danger: {
			label: $dangerLabel.toUpperCase(),
			className: $dangerClass,
			callback: function() {
			  bootbox.hideAll();
			}
		  }
		}
	  });
	});

	$('#credit-limit-form input[name=no_credit_limit]').on("change", function() {
		if($('#credit-limit-form input[name=no_credit_limit]').is(":checked")) {
			$('#toggle-credit').hide("fast");
		} else {
			$('#toggle-credit').show("fast");
		}
	});

	/** Submit credit limit form **/
	$('#credit-limit-form').submit(function(e) {
		e.preventDefault();
		$that = $(this);
		$.ajax({
			url: $that.attr("action"),
			type: $that.attr("method"),
			data: $that.serialize(),
			success: function(response) {
				if(response === 'success') {
				  $('#credit-limit-modal').modal('hide');
				  $.notify({message: "Credit Limit Updated."},{type: "success"});
				} else {
				  bootBoxAlert(response);
				}
			}
		});

	});

	/**
	 * Groups Toggler
	 */
	$('#account-group-form input[name=no_group]').on("change", function() {
		if($('#account-group-form input[name=no_group]').is(":checked")) {
			$('#toggle-groups').hide("fast");
		} else {
			$('#toggle-groups').show("fast");
		}
	});

	/** Submit account group form **/
	$('#account-group-form').submit(function(e) {
		e.preventDefault();
		$that = $(this);
		$.ajax({
			url: $that.attr("action"),
			type: $that.attr("method"),
			data: $that.serialize(),
			success: function(response) {
				if(response === 'success') {
				  $('#account-group-modal').modal('hide');
				  $.notify({message: "Account Group Changed."},{type: "success"});
				} else {
				  bootBoxAlert(response);
				}
			}
		});
	});

	// submit new product form
	$('#new-product-form').on('submit', function(e) {
		e.preventDefault();

		var that = $(this);

		startHoldTight();

		$.ajax({
			url: that.attr('action'),
			method: that.attr('method'),
			data: that.serialize(),
			success: function(data) {
				if(data == 'success') {
					$.notify({message: "Item created!"},{type: "success"});
					that[0].reset();
					$('#new-product-form input[name=code]').focus();
				} else {
					bootBoxAlert(data);
				}
			},
			error: function(xhr, textStatus, errorThrown ) {
				bootBoxAlert(textStatus + ': ' + errorThrown);
			},
			complete: function(xhr, textStatus) {
				stopHoldTight();
				console.log(xhr);
			}
		});
	}); 

})
// end ready

	function fetching(data) {
		return '<div class="fetching"><p><i class="fa fa-spinner faa-spin animated"></i></p><p>Fetching '+data+'...</p></div>';
	}
	
	// function editPricing(contact_id, product_code) {
	// 	$('.modal-footer').hide();
	// 	$('.modal-body.product').html(fetching("Product Information"));
	// 	$.get('/products/get-product/'+contact_id+'/'+product_code, function(data) {
	// 		$('.modal-body.product').html(data);
	// 		$('.modal-footer').show();
	// 		$('[data-toggle="tooltip"]').tooltip();
	// 	});
	// }

	// function deletePricing(product_id) {
	// 	bootbox.confirm("Are you sure?", function(result) {
	// 		if(result == false) {
	// 			return;
	// 		} else {
	// 			$.ajax({
	// 			  url: '/products/delete-product/'+ product_id,
	// 			  type: 'DELETE',
	// 			  data: { _token: $('meta[name=csrf_token]').attr("content") },
	// 			  success: function(data) {
	// 				  if(data === 'success') {

	// 					  $('.table tr[id='+ product_id +']').remove();
	// 				  }
	// 				  else {
	// 					  alert('Something went wrong.');
	// 				  }
	// 			  } 
	// 			});
	// 		}
	// 	}); 
	// }


	function bootBoxAlert(message)
	{
	  bootbox.alert(message, function() {});
	}

	

	function bootBoxAjax()
	{  
	  $.ajax({
		  url: $url,
		  type: $method,
		  data: $data,
		  success:function(response){
			if(response === "success")
			{
			  location.reload();
			}
			else 
			{
			  bootBoxAlert(response);
			}
		  }
	  });
	}

	function showUnleashedOverlay()
	{
		$('body').prepend('<div class="unleashed-overlay"></div><div class="unleashed-overlay-container"></div>');
		$('body').css("overflow-x", "auto");
		$("body").css("overflow-y", "hidden");
	}

	function hideUnleashedOverlay()
	{
		$('.unleashed-overlay').remove();
		$('.unleashed-overlay-container').remove();
		$("body").css("overflow-x", "auto");
		$("body").css("overflow-y", "auto");
	}

	function startHoldTight() {
	  	$.isLoading({text: "Please wait "});
	}

	function stopHoldTight() {
	  	$.isLoading("hide");
	}

	function startSpinner()
	{
	   var opts = {
		lines: 13, // The number of lines to draw
		length: 7, // The length of each line
		width: 4, // The line thickness
		radius: 10, // The radius of the inner circle
		rotate: 0, // The rotation offset
		color: '#000', // #rgb or #rrggbb
		speed: 1, // Rounds per second
		trail: 60, // Afterglow percentage
		shadow: false, // Whether to render a shadow
		hwaccel: false, // Whether to use hardware acceleration
		className: 'spinner', // The CSS class to assign to the spinner
		zIndex: 2e9, // The z-index (defaults to 2000000000)
		top: '65px', // Top position relative to parent in px
		left: '0px' // Left position relative to parent in px
	  };
	  var spinner = new Spinner(opts).spin();
	  $(".hold-tight-container").append(spinner.el);
	}

	function stopSpinner()
	{
	  var spinner = new Spinner().spin();
	  spinner.stop();
	}


	function deleteAddress(url, token) {
		$.ajax({
		  url: url,
		  type: 'DELETE',
		  data: { _token: token },
		  success: function(data) {
			  if(data === 'success') {
				  window.location.reload();
			  }
			  else {
				  alert('Something went wrong.');
			  }
		  } 
	  });
	}

	function editAddress(address_id) {
	  var form =  $('body').find('#address-form');
	  form.prepend('<input name="address_id" type="hidden" value="'+address_id+'">');
	  $('input[name="address_name"]').val($('body').find('.address-name-'+address_id).html());
	  $('input[name="street_address"]').val($('body').find('.address-street-'+address_id).html());
	  $('input[name="city"]').val($('body').find('.address-city-'+address_id).html());
	  $('input[name="state"]').val($('body').find('.address-state-'+address_id).html());
	  $('input[name="post_code"]').val($('body').find('.address-postcode-'+address_id).html());
	  $('input[name="country"]').val($('body').find('.address-country-'+address_id).html());
	  $('#form-header').html("Edit");
	}

	/**
	 * CREDIT LIMIT
	 */
	

	function loadCreditLimitContactId(contact_id) {
	  $('#credit-limit-form input[name=contact_id]').val(contact_id);
	  startHoldTight()
	  $.ajax({
			url: '/accounts/get-credit-limit/' + contact_id,
			type: 'GET',
			success: function(response) {
			  console.log(response);
				stopHoldTight();
				$('#credit-limit-modal').modal('show');
				if(response.credit_limit == 0) {
					$('#toggle-credit').css("display", "none");
					$('#credit-limit-form input[name=credit_limit]').val(response.credit_limit);
					$('#client-account .credit-limit').html(accounting.formatMoney(response.credit_limit));
					$('#client-account .credit-terms').html(response.credit_terms);
					$('#client-account .credit-outstanding').html(accounting.formatMoney(response.contact.Balances.Outstanding));
					$('#client-account .credit-overdue').html(accounting.formatMoney(response.contact.Balances.Overdue));
					$('#credit-limit-form input[name=no_credit_limit]').prop("checked", true);
				} else {
					$('#toggle-credit').css("display", "block");
					$('#credit-limit-form input[name=credit_limit]').val(response.credit_limit);
					$('#client-account .credit-limit').html(accounting.formatMoney(response.credit_limit));
					$('#client-account .credit-terms').html(response.credit_terms);
					$('#client-account .credit-outstanding').html(accounting.formatMoney(response.contact.Balances.Outstanding));
					$('#client-account .credit-overdue').html(accounting.formatMoney(response.contact.Balances.Overdue));
					$('#credit-limit-form input[name=no_credit_limit]').prop("checked", false);
				}
			}
		});
	}

	/** DELETE GROUPS **/
	function deleteGroup(id) {
	  bootbox.confirm("Completing this action removes the group, along with any accounts and products assigned to it.<br><br>Are you sure you want to delete this group?", function(result) {
		  if(result == false) {
			  return;
		  } else {
			  $.ajax({
				url: '/groups/'+id,
				type: 'DELETE',
				data: { _token: $('meta[name=csrf_token]').attr("content") },
				success: function(data) {
					if(data === 'success') {
						$('.groups-table tr[id='+ id +']').remove();
						$.notify({message: "Group Deleted."},{type: "success"});   
					}
					else {
						alert('Something went wrong.');
					}
				} 
			  });
		  }
	  });
	 }

	function deleteGroupPricing(group_product_id) {
		bootbox.confirm("Are you sure?", function(result) {
			if(result == false) {
				return;
			} else {
				$.ajax({
				  url: '/groups/delete-group-product/'+ group_product_id,
				  type: 'DELETE',
				  data: { _token: $('meta[name=csrf_token]').attr("content") },
				  success: function(data) {
					  if(data === 'success') {
						  $('.table tr[id='+ group_product_id +']').remove();
					  }
					  else {
						  alert('Something went wrong.');
					  }
				  }
				});
			}
		}); 
	}

	function loadAccountGroup(contact_id, contact_name) {
		$('#account-group-form input[name=contact_id]').val(contact_id);
		$('#account-group-form input[name=contact_name]').val(contact_name);
		startHoldTight()
		$.ajax({
			url: '/groups/'+ contact_id +'/accounts',
			type: 'GET',
			success: function(response) {
				console.log(response);
				stopHoldTight();
				$('#account-group-modal').modal('show');
				if(response.length < 1){
					$('#toggle-groups').css("display", "none");
					$('#account-group-form input[name=no_group]').prop("checked", true);
				} else {
					$('#toggle-groups').css("display", "block");
					$('#account-group-form select[name=group]').val(response[0].group_id);
					$('#account-group-form input[name=no_group]').prop("checked", false);
				}
			}
		});
	}

	/**
	 * Archives a contact in Xero
	 */
	function archiveAccount(contact_id, contact_name) {
	  	bootbox.confirm("Are you sure you want archive " + contact_name + "?", function(result) {
			if(result == false) {
				return;
			} else {
				startHoldTight()
				$.ajax({
					type: 'DELETE',
					url: '/accounts/' + contact_id,
					success: function(data) {
						if(data === 'success') {
							$('#accSearchBtn').trigger('click');
							bootBoxAlert("Account successfully archived.");
						} else {
							bootBoxAlert(data);
						}
					},
					complete: function(xhr, status) {
						stopHoldTight();
					}
				});
			}
	  	});
	 }

	function showGroup(group_id) {
		startHoldTight()
		$.ajax({
			url: '/groups/'+ group_id,
			type: 'GET',
			success: function(response) {
				stopHoldTight();
				$('.show-group').html(response);
				$('#show-group-modal').modal('show');
			}
		});
	}

	function removeFromGroup(account_id) { 
		startHoldTight()
		$.ajax({
			url: '/groups/'+ account_id + '/remove-from-group',
			type: 'DELETE',
			data: { _token: $('meta[name=csrf_token]').attr("content") },
			success: function(response) {
				console.log(response);
				stopHoldTight();
				if(response === 'success') {
				  $('.table-show-accounts tr[id='+ account_id +']').remove();
				  $.notify({message: "Account removed from group."},{type: "success"});
				} else {
				  alert('Something went wrong.')
				}
			}
		});
	}

	function editGroupPricing(group_id, product_code) {
		$('#edit-group-pricing-modal .modal-footer').hide();
		$('#edit-group-pricing-modal .modal-body.group-product').html(fetching("Group Product Information"));
		$.get('/groups/get-product/'+group_id+'/'+product_code, function(data) {
			$('#edit-group-pricing-modal .modal-body.group-product').html(data);
			$('#edit-group-pricing-modal .modal-footer').show();
			$('[data-toggle="tooltip"]').tooltip(); 
		});
	}

	/** ADD/REMOVE ACCOUNTS TO/FROM GROUPS **/
	var addRemoveGroup = new Vue({
	  el: '#add-remove-group',
	  data: {
		  fetching: true,
		  empty: true
	  },
	  ready: function() {
		  this.$set('group_id', $('#add-remove-group input[name=group_id]').val());
		  this.fetchAccounts();
	  },
	  methods: {
		fetchAccounts: function() {
			this.$http.get('/groups/'+ this.group_id + '/accounts', function(accounts) {
			  this.$set('accounts', accounts);
			  this.fetching = false;
			  if(accounts.length > 0)
				this.empty = false;
			});
		},
		removeAccount: function() {
			alert('removing account..')
		}
	  }
	});


	/** DELETE INVOICE TEMPLATE **/
	function deleteInvoiceTemplate(template_id) {
	  bootbox.confirm("Completing this action will permanently remove the invoice template.<br><br>Are you sure you want to delet this template?", function(result) {
		  if(result == false) {
			  return;
		  } else {
			  $.ajax({
				url: '/invoice-templates/'+template_id,
				type: 'DELETE',
				data: { _token: $('meta[name=csrf_token]').attr("content") },
				success: function(data) {
					if(data === 'success') {
						$('.templates-table tr[id='+ template_id +']').remove();
						$.notify({message: "Invoice Template Deleted."},{type: "success"}); 
					}
					else {
						alert('Something went wrong.');
					}
				} 
			  });
		  }
	  });
	}

	// change account price list
	function changeGroupPriceList(group_id) {
		var price_list = $('select[name=price_list_'+ group_id +']').val();
		
		startHoldTight();

		$.ajax({
			url: '/groups/' + group_id + '/price-list/' + price_list,
			method: 'POST',
			success: function(data) {
				if(data == 'success') {
					$.notify({message: "Group Updated."},{type: "success"});
				} else {
					bootBoxAlert(data);
				}
			},
			error: function(xhr, textStatus, errorThrown ) {
				bootBoxAlert(textStatus + ': ' + errorThrown);
			},
			complete: function(xhr, textStatus) {
				stopHoldTight();
			}
		});
	}

	// change account price list
	function changeAccountPriceList(account_id) {
		var price_list = $('select[name=price_list_'+ account_id +']').val();

		startHoldTight();

		$.ajax({
			url: '/accounts/' + account_id + '/price-list/' + price_list,
			method: 'POST',
			success: function(data) {
				if(data == 'success') {
					$.notify({message: "Account Updated."},{type: "success"});
				} else {
					bootBoxAlert(data);
				}
			},
			error: function(xhr, textStatus, errorThrown ) {
				bootBoxAlert(textStatus + ': ' + errorThrown);
			},
			complete: function(xhr, textStatus) {
				stopHoldTight();
				// console.log(xhr);
			}
		});
	}

	// change salesperson
	function changeAccountSalesperson(account_id) {
	  var salesperson = $('select[name=salesperson_'+ account_id +']').val();

	  startHoldTight();

	  $.ajax({
		  url: '/accounts/' + account_id + '/salesperson/' + salesperson,
		  method: 'POST',
		  success: function(data) {
			if(data == 'success') {
			  $.notify({message: "Account Updated"},{type: "success"});
			} else {
			  bootBoxAlert(data);
			}
		  },
		  error: function(xhr, textStatus, errorThrown ) {
			bootBoxAlert(textStatus + ': ' + errorThrown);
		  },
		  complete: function(xhr, textStatus) {
			stopHoldTight();
			// console.log(xhr);
		  }
		});
	}

	/**
	 * Show salesperson accounts
	 */
	function showSalesAccounts(salesperson_id) {
		startHoldTight()
		$.ajax({
			url: '/salespersons/'+ salesperson_id,
			type: 'GET',
			success: function(response) {
				stopHoldTight();
				$('.show-accounts').html(response);
				$('#show-sales-accounts-modal').modal('show');
			}
		});
	}