$(document).ready(function() {

	// get the info for a buy link group
	$('.edit-buy-link-group').on('click', function() {
		var id = $(this).data('id');
		$.get('/buylink/' + id, function(data) {
			data = JSON.parse(data);

			// add data to fields 
			$('#edit-buylink-group-form input[name=group_id]').val(data.id);
			$('#edit-buylink-group-form input[name=group]').val(data.group_name);
			$('#edit-buylink-group-form input[name=supplier_id]').val(data.bl_supplier_id);
			$('#edit-buylink-group-form input[name=ftp_host]').val(data.bl_ftp_host);
			$('#edit-buylink-group-form input[name=ftp_username]').val(data.bl_ftp_username);
			$('#edit-buylink-group-form input[name=ftp_password]').val(data.bl_ftp_password);

			// open modal
			$('#edit-buylink-group-modal').modal('show');
		})
	});

	$('#edit-buylink-group-form').on('submit', function(e) {
		e.preventDefault();

		if($('#edit-buylink-group-form input[name=supplier_id]') == ''
			|| $('#edit-buylink-group-form input[name=ftp_host]') == ''
			|| $('#edit-buylink-group-form input[name=ftp_username]') == ''
			|| $('#edit-buylink-group-form input[name=ftp_password]') == '') {
			
			alert('Not all required fields have been filled.');
		} else {

			var id  = $('#edit-buylink-group-form input[name=group_id]').val();
			$.ajax({
			  	type: 'PUT',
			  	url: '/buylink/' + id,
			  	data: $('#edit-buylink-group-form').serialize(),
			  	success: function(data) {
			  		if(data == 'success') {
			  			location.reload();
			  		}
			  	},
			  	error: function(xhr, textStatus, errorThrown) {
			    	alert('An unknown error occured');
			  	},
			})
		}
	});

	// add a new group as a buy link group
	$('#add-buylink-group-form').on('submit', function(e) {
		e.preventDefault();

		if(	$('#add-buylink-group-form select[name=group]') == ''
			|| $('#add-buylink-group-form input[name=ftp_host]') == ''
			|| $('#add-buylink-group-form input[name=ftp_username]') == ''
			|| $('#add-buylink-group-form input[name=ftp_password]') == '') {
			
			alert('Not all required fields have been filled.');
		} else {
			$.ajax({
			  	type: 'POST',
			  	url: '/buylink',
			  	data: $('#add-buylink-group-form').serialize(),
			  	success: function(data) {
			  		if(data == 'success') {
			  			location.reload();
			  		}
			  	},
			  	error: function(xhr, textStatus, errorThrown) {
			    	alert('An unknown error occured');
			  	},
			})
		}
	});

});
// end ready

// remove a buylink group
function deleteBuyLinkGroup(id) {

	// confirm the user wants to continue
	if(!confirm("Are you sure you want to remove this Buy Link group?"))
		return;

	$.ajax({
	  	type: 'DELETE',
	  	url: '/buylink/' + id,
	  	success: function(data) {
	  		if(data == 'success') {
	  			location.reload();
	  		}
	  	},
	  	error: function(xhr, textStatus, errorThrown) {
	    	alert('An unknown error occured');
	  	},
	})
}

	
